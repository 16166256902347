<template>
  <div class="streaming-not-started pa-2 d-flex justify-center align-center">
    <div>
      <v-btn color="primary" rounded @click="$emit('start')">
        <v-icon>play_arrow</v-icon>
        <span class="px-2" v-text="$t('streaming.start')" />
      </v-btn>
      <br>
      <div class="mt-2 d-flex justify-center align-center">
        <a
          href="https://streaming-troubleshooting.web.app/"
          target="_blank"
          rel="noopener noreferrer"
          v-text="$t('streaming.test')"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StramingNotStarted',
}
</script>

<style lang="scss">
.streaming-not-started {
  height: 100%;
}
</style>
